import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { PageWidgetData } from 'theme/stores/cms/types';
import { decodePhpHtmlString } from '@zento-lib/util/html';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { Link } from '../../../atom/Link/Link';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { IPageLinkWidget } from './PageLinkWidget.d';
import style from './style.scss';

@Component({})
export class PageLinkWidget extends BaseComponent<IPageLinkWidget, unknown> {
  /**
   * An object containing component data
   */
  @Prop({ type: Object, required: true })
  pageWidgetData: PageWidgetData;

  get imageSizes() {
    const config = this.extended.$config.zento.images;

    return this.pageWidgetData.events && config.pageEventWidget.layout === 'layout1'
      ? config.pageEventWidget
      : config.pageLinkWidget;
  }

  render() {
    // const layout = this.extended.$config.zento.images.pageLinkWidget.layout; // TODO: later on adjust styles based on layout

    return (
      <section
        class={{
          [style.pageWidgetWrapper]: true,
          [style.layout1]: this.pageWidgetData.events && this.imageSizes.layout === 'layout1',
          [style.layout12]: this.imageSizes.layout === 'layout12',
        }}>
        <div class={style.container}>
          <Link to={'/i/' + this.pageWidgetData.page_uri} class={style.sectionLink}>
            <MainImage
              image={{ src: this.pageWidgetData.image, loading: this.pageWidgetData.image }}
              width={this.imageSizes.width}
              height={this.imageSizes.height}
              tabletWidth={this.imageSizes.tabletWidth}
              tabletHeight={this.imageSizes.tabletHeight}
              desktopWidth={this.imageSizes.desktopWidth}
              desktopHeight={this.imageSizes.desktopHeight}
              folder=''
              class={style.animatedImg}
            />

            <div class={style.contentBox}>
              {this.pageWidgetData.heading ? <h3 class={style.title}>{this.pageWidgetData.heading}</h3> : null}
              {this.pageWidgetData.description ? (
                <InnerHTML
                  contents={decodePhpHtmlString(this.pageWidgetData.description)}
                  class={style.widgetContent}
                  key='widget-content'
                />
              ) : null}
              {this.pageWidgetData.button_label ? (
                <span class={style.button}>
                  <span>{this.pageWidgetData.button_label}</span>
                </span>
              ) : null}
            </div>
          </Link>
        </div>
      </section>
    );
  }
}
